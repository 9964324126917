<template>
<div class='page'>
    <div class="content">
        <div class="H1">CNC MACHINING MATERIALS</div>
        <div class="mes">
            <div class="title">NAME</div>
            <div class="title">DESCRIPTION</div>
            <div class="title">DETAILS</div>
        </div>
        <div class="lists" v-for="(item,index) in materialslist" :key="index">
            <div class="model">
                <div class="modelImg"><img :src=item.img alt=""></div>
                <div class="bzbg">
                    <div class="bz">

                        <div class="bzname">NAME</div>
                        <div class="bzData">{{item.bz}}</div>
                      
                        
                        
                        </div>
                <div class="descripyion">
                    
                    <div class="descripyionname">DESCRIPTION</div>
                    <div class="descripyionData"> {{item.descripyion}}</div>
              
                    
                    </div>
                <div class="details">


                    <div class="detailsname">DETAILS</div>
                    <div class="detailsnData">
                        <div class="detailsTitle" @click="show(item)">{{item.details}}</div>
                           <div class="detailsfun">
                              <img v-if="item.isshow" @click="show(item)" src="../assets/img/sl.png" alt="">
                              <img v-else @click="show(item)" src="../assets/img/xl.png" alt="">
                           </div>
                    </div>
                    
                </div>
                </div>
                
            </div>
            <div class="modeData" v-if="item.isshow" >
                  <div class="left">
                      <div class="leftTitle">
                          <div class="TitleImg"><img src="../assets/img/Description.png" alt=""></div>
                          <div class="Titlename">Description</div>
                      </div>
                      <div class="list1">
                          <div class="list1model" v-for="(listitem,index1) in item.data.list1" :key="index1">
                              <div class="list1modelName">{{listitem.name}}</div>
                              <div class="info">{{listitem.info}}</div>

                          </div>
                      </div>
                  </div>
                  <div class="rig">
                        <div class="leftTitle">
                          <div class="TitleImg"><img src="../assets/img/Characteristics.png" alt=""></div>
                          <div class="Titlename">Characteristics</div>
                      </div>
                       <div class="list2">
                           <div class="list2mdoel" v-for="(list2item,index2) in item.data.lists2" :key="index2">
                               <div class="list2mdoelname1">{{list2item.title}}</div>
                                <div class="list2mdoelname2">{{list2item.data}}</div>

                           </div>

                      </div>
                  </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
data() {
return {
    index:0,
  
}
},
methods: {
    show(item){
// item.isshow=!item.isshow
     
         this.$set(item,'isshow',!item.isshow)

    },
 
},
mounted() {},
created() {},
props:{materialslist:[]},
watch:{ 
    lists:{
        handler(newval,oldval){
console.log(newval,oldval);
        },
        deep:true
    }
},
components:{}
}
</script>
<style scoped>

.bzname{
    display: none;
    /* padding: 33px 0; */
    color: #DA251D;
    font-weight: bold;
}
.descripyionname{
        display: none;
    /* padding: 33px 0; */
    color: #DA251D;
    font-weight: bold;
}
.detailsnData{
    display: flex;
    align-items: center
}
 
.detailsname{
        display: none;
    /* padding: 33px 0; */
    color: #DA251D;
    font-weight: bold;
}
img{
    width: 100%;
}
.lists{
    border-bottom:1px solid  #cccccc;
    padding: 20px 0
}
.modelImg{
    width: 120px;
}
.left{
    width: 590px;    
}
.leftTitle{
    display: flex;
    align-items: center
}
 
.rig{
    width: 490px;
}
.list2mdoel{
    display: flex;
    border-bottom:1px solid  #CCCCCC;
    padding-bottom: 15px;
    justify-content: space-between;
    margin-top:25px; 
}
.info{
    font-size: 17px;
    color: #666666;
    line-height: 27px;
}
.list1modelName{
font-size: 18px;
margin-bottom:15px 

}
.list1model{
    margin-bottom:20px 
}
.page{
    width: 100%;
    margin: 30px 0
}
.list2mdoelname1{
    width: 50%;
    font-weight: bold;
 
}
.list2mdoelname2{
        width: 50%;
    color: #7B7B7B;
      font-weight: bold;
}
.content{
    width: 1200px;
    margin: auto
}
.TitleImg{
    width: 40px;
    height: 40px;
 
}
.Titlename{
    margin-left:6px ;
    
    font-weight: bold;
    font-size: 18px;
}
.modeData{
    padding: 28px 25px;
    background: #F5F5F5;
    display: flex;
    justify-content: space-between;
    margin: 10px 0
}
.bz{
    width: 149px;
    display: flex;
 
font-size: 18px;
font-family: Source Han Sans CN;
font-weight: 500;
color: #333333;
line-height: 30px;
}
.detailsfun{
    width: 25px;
    height: 25px;
    cursor: pointer;
    

}

.H1{
    width: 100%;
    text-align: center;
    font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 60px;
margin-bottom: 48px
}

.detailsTitle{
    margin-right:20px;
    cursor: pointer;
    text-align: left
}
.details{
    width: 330px;
 
font-size: 18px;
font-family: Source Han Sans CN;
font-weight: 500;
color: #DA251D;
line-height: 30px;
display: flex;
align-items: center;
text-align: center
 
}
.bzbg{
    width:80%;
    display: flex;justify-content: space-between
}
.descripyion{
    width: 346px;
    display: flex;
 
font-size: 18px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #666666;
line-height: 30px;
}
.mes{
    display: flex;
    /* justify-content: space-between; */
       padding-left:30px ;
    border-bottom:1px solid #666666 ;

 
 
    
}
.model{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:25px 
 
}
.bzData{
    font-weight: bold;
 
}
.title{
   
  width: 32%;
  text-align: center;
    padding: 33px 0;
    color: #DA251D;
    font-weight: bold
}
.list1{
    margin-top: 30px;
}
.list2{
    margin-top: 30px;
}
.title:first-child{
    text-align: left;
}
 @media screen and (max-width: 760px) {
     .H1{
         font-size: 27px;
              margin-bottom: 12px;
     }

.list1,.list2{
    margin-top: 15px
}
.content{
    width: 90%;
    min-width: 330px
} 
.model{
    display: block
}
 
.bz{
    font-size: 15px;
    width: 100%;
    margin: 20px 0;
    /* padding-bottom:10px; 
    border-bottom:1px solid #cccccc  */
}
.mes{
    display: none
}
.detailsname{
    width: 180px;
       font-size: 15px;
    display: inherit;
    margin-right:20px 
}
.bzData,.descripyionData{
    width: 70%;
    border-bottom: 1px solid #ccc;
    line-height: 23px;
} 
 
 .list2mdoel{
     font-size: 14px;
     margin-top:15px 
 }
 
.detailsnData{
    align-items: center;
    width: 70%;
} 
.details{
    width: 100%;
    margin: 20px 0;
    text-align: left
}
.bzname{
    width: 180px;
    display: inherit;
    margin-right:20px 
}
.descripyionname{
    width: 180px;
      display: inherit;
    margin-right:20px 
}
.modelImg{
    width: 100%;
    max-width: 158px;
 
    /* margin: auto; */
 
}
.lists{
    border-top:1px solid #333333 
}

.bzbg{
    width: 100%;
    display: block;
    margin: auto
}
.descripyion{
    width: 100%;
    font-size: 15px;
    margin: 20px 0;
    /* border-left:2px solid #DA251D; */
    /* padding-left:5px    */
}
.detailsTitle{
    font-size: 16px;
    line-height: 23px;
}
.left{
    width: 100%;
}
.rig{
    width: 100%;
}
.info{
    font-size: 13px
}
.list1modelName{
    font-size: 17px
}
.modeData{
    display: block
}
.details{
    display: flex;
    align-items: center
}
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
     .H1{
         font-size: 27px;
             margin-bottom: 30px;
     }
.content{
    width: 90%;
}
.bz{
    font-size: 15px;
    width: 100%;
    margin: 20px 0
}
.mes{
    display: none
}
.details{
    display: flex;
    align-items: center
}
.detailsname{
    width: 120px;
       font-size: 15px;
    display: inherit;
    margin-right:20px 
}
.bzData,.descripyionData{
    width: 70%;
}
 
.detailsnData{
    align-items: center;
    width: 65%;
} 
.details{
    width: 88%;
    margin: 20px 0
}
.bzname{
    width: 120px;
    font-size: 20px;
    display: inherit;
    margin-right:20px 
}
.descripyionname{
    width: 120px;
      display: inherit;
    margin-right:20px 
}
.modelImg{
    width: 30%;
}
.lists{
    border-top:1px solid #333333 
}
.bzbg{
    width: 60%;
    display: block;
}
.descripyion{
    width: 100%;
    font-size: 15px;
    margin: 20px 0
}
.detailsTitle{
    font-size: 16px
}
.left{
    width: 48%;
}
.rig{
    width: 48%;
}
.info{
    font-size: 15px
}
.list1modelName{
    font-size: 16px
}

.list2mdoel{
    font-size: 15px;
    margin-top:20px 
    /* margin-bottom: 10px */
}
.info{
    font-size: 14px
}

}
@media screen and (min-width: 1020px) and (max-width: 1350px) {

.content{
    width: 90%;
}
.bz{
    font-size: 15px
}
.bzbg{
    width: 85%;
}
.descripyion{
    font-size: 15px
}
.detailsTitle{
    font-size: 16px
}
.left{
    width: 48%;
}
.rig{
    width: 48%;
}
.info{
    font-size: 15px
}
.list1modelName{
    font-size: 17px
}
}
@media screen and (min-width: 1350px) {
    
}
</style>