<template>
<div class='page'>
 <div class="banner">
            <div class="w">
                <div class="info">
                    <div class="title">Plastic ＣNC machining</div>
                    <p class="title1">A wide range of plastics for CNC machining, with applications in multiple industries. Ideal for both one-off prototypes and end-use custom parts.</p>
                    
                  
                </div>
            </div>
        </div>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
  <materials :materialslist="materialslist"></materials>
  
</div> 
</template>
<script>
import myhead from '../../components/myHead.vue'
import bread from '../../components/bread.vue'
import materials from '../../components/materials.vue'
export default {
data() {
return {
      hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
    materialslist:[
    {
        isshow:false,
        img:require('../../assets/img/materials1.png'),
        bz:'POM (Delrin/Acetal)',
        descripyion:'High stiffness, high accuracy, low friction, easy to machine.',
        details:'Browse POM materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials2.png'),
        bz:'Nylon',
        descripyion:'Excellent mechanical properties, thermal, chemical and abrasion resistant.',
        details:'Browse Nylon materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials3.png'),
        bz:'ABS',
        descripyion:'Common thermoplastic, impact resistant, easy to machine.',
        details:'Browse ABS materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials4.png'),
        bz:'PEEK',
        descripyion:'High-performance thermoplastic, very high strength, thermal and chemical resistant.',
        details:'Browse PEEK materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials5.png'),
        bz:'PTFE (Teflon)',
        descripyion:'Low friction, chemical and thermal resistant.',
        details:'Browse PTFE materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials6.png'),
        bz:'Polycarbonate',
        descripyion:'High toughness, excellent impact strength, transparent.',
        details:'Browse Polycarbonate materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials7.png'),
        bz:'Polyethylene',
        descripyion:'Excellent strength-to-weight ratio, impact and weather resistant.',
        details:'Browse Polyethylene materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials8.png'),
        bz:'PVC',
        descripyion:'Excellent chemical and weather resistance and good toughness.',
        details:'Browse PVC materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials9.png'),
        bz:'PMMA (Acrylic)',
        descripyion:'Transparent rigid plastic often used as a substitute for glass.',
        details:'Browse PMMA materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials10.png'),
        bz:'PET',
        descripyion:'Tough plastic that offers excellent wear resistance and mechanical strength.',
        details:'Browse PET materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials11.png'),
        bz:'Polypropylene',
        descripyion:'Excellent chemical resistance. Food-safe grades available.',
        details:'Browse Polypropylene materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials12.png'),
        bz:'G-10',
        descripyion:'High strength, low moisture absorption, and high level of electrical insulation and chemical resistance.',
        details:'Browse G-10 materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },{
        isshow:false,
        img:require('../../assets/img/materials13.png'),
        bz:'FR4',
        descripyion:'Flame retardant glass fiber epoxy laminate.',
        details:'Browse FR4 materials',
        data:{
            list1:[{
                name:'Applications',
                info:'CNC machining produces parts with excellent mechanical properties, accuracy and repeatability from metal and plastic. 3-axis & 5-axis CNC milling available.',
            },{
                name:'Strengths',
                info:'Excellent mechanical properties,High accuracy & repeatabillity',
            },{
                name:'Weaknesses',
                info:'Greater geometry restrictions than 3D printing',
            }],
            lists2:[{
                title:'Price', 
                data:'$'
            },{
                title:'Lead Time', 
                data:'< 10 days'
            },{
                title:'Wall Thickness', 
                data:'0.75mm'
            },{
                title:'Tolerances', 
                data:'±0.125mm (±0.005″)'
            },{
                title:'Max part size', 
                data:'200 x 80 x 100 cm'
            }]
        }


    },]
}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{
  myhead,
  materials,
  bread,
}
}
</script>
<style scoped lang="scss">
.page{
  
}
  .banner{
        background: url('~@/assets/img/PlasticＣNCmachiningbanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
            display: flex;
            justify-content: flex-end;
            margin-top: 87px;
        }
        .btn {
            display: inline-block;
             padding: 10px 15px;
          
            cursor: pointer;
            border: 1px solid #071930;
            // font-size: 16px;
            text-align: center;

            color: #071930;
            // margin-left:64px ;
        }
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #ffffff;
          
      
        }
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: #ffffff;
           transition: 0.25s;
           font-weight: bold
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
      position: absolute;
    right: 0vw;
    //    margin-top: 25vw;
    // margin-left: 3vw;
    top: 167px;
    }
    .w{
        width: 90%;
        min-width: 330px;
    }
    .title{
        font-size: 23px;
        font-weight: bold;
          margin-bottom: 6vw;
          width: 210px;
    } 
    .title1{
           font-size: 15px;
        font-weight: bold;
        width: 62vw;
          // line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
       height: 421px;
    background-position: 37% 54% !important;
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

    .w{
        width: 90%;
    }
    .title{
        font-size: 35px;
        margin-bottom: 54px;
    }
    .title1{
            font-size: 22px;
              line-height: 36px;
          width:50vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 620px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .w{
        width: 90%;
    }
    .title{
        font-size: 40px;
        margin-bottom: 54px;
    }
   .title1{
        font-size: 22px;
        width:45vw;
          line-height: 36px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
    .w{
        width: 1200px;
        margin: auto
    }
    .title{
        font-size: 50px;
        margin-bottom: 54px;
    }
    .title1{
           font-size: 24px;
        width: 620px;
          line-height: 36px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}
</style>